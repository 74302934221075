import {useEffect} from 'react'
import {KTCard} from '../../../../../../_metronic/helpers'
import {generateCurrencyFormat} from '../../../../../helpers/generate-currency-format'
import {useAffiliatorCompanyStore} from '../states/affiliator-state'
import DashboardCardAffiliatorComponent from './dashboard-card-affiliator.component'
import MiniCardComponent from './mini-card-dashboard.component'
import {useDashboardAffiliateStore} from '../../../program/program-affiliate/states/program-affiliate.state'
import DonationSegmentChart from './donation-chart.component'
import {useCustomerStore} from '../../../crm/customer-management/states/customer.state'

const TotalDonationAffiliatorCardComponent = (props) => {
  const {totalDonationMember, getTotalDoantionMember, totalDonationMemberLoading} =
    useAffiliatorCompanyStore()
  const {
    customerByAffiliatorLoading,
    customerByAffiliatorModel,
    getCustomerByAffiliator,
    navList,
    activeNavIndex,
  } = useCustomerStore()
  const {
    getTotalDonationAffiliateByTransfer,
    totalDonationAffiliateByTransfer,
    totalDonationAffiliateByTransferLoading,
    totalDonationAffiliateByTunai,
    totalDonationAffiliateByTunaiLoading,
  } = useDashboardAffiliateStore()

  useEffect(() => {
    getCustomerByAffiliator(
      {
        page: 0,
        size: 10,
        reference: navList[activeNavIndex].reference,
      },
      props?.userId
    )
  }, [])

  useEffect(() => {
    getTotalDoantionMember(props?.userId)
  }, [props?.userId])

  useEffect(() => {
    getTotalDonationAffiliateByTransfer(props?.userId)
  }, [])

  return (
    <>
      <DashboardCardAffiliatorComponent
        title='Total Donation'
        color='success'
        value={generateCurrencyFormat(
          totalDonationMember + totalDonationAffiliateByTransfer + totalDonationAffiliateByTunai ??
            0
        )}
        subtitle='Total donasi yang terkumpul'
        loading={totalDonationMemberLoading}
      />
      <DashboardCardAffiliatorComponent
        title='Total Donatur'
        color='warning'
        value={customerByAffiliatorModel?.data?.total ?? 0}
        subtitle='Total Donatur Terkumpul'
        loading={customerByAffiliatorLoading}
      />

      <MiniCardComponent
        title='Transfer'
        color='primary'
        value={generateCurrencyFormat(totalDonationAffiliateByTransfer ?? 0)}
        icon={<i className={`bi bi-send-check-fill text-white fs-2`} style={{marginRight: 10}}></i>}
        loading={totalDonationAffiliateByTransferLoading}
      />
      <MiniCardComponent
        title='Tunai'
        color='warning'
        value={generateCurrencyFormat(totalDonationAffiliateByTunai ?? 0)}
        icon={<i className={`bi bi-cash-coin text-white fs-2`} style={{marginRight: 10}}></i>}
        loading={totalDonationAffiliateByTunaiLoading}
      />
      <MiniCardComponent
        title='Affiliate'
        color='danger'
        value={generateCurrencyFormat(totalDonationMember ?? 0)}
        icon={<i className={`bi bi-share text-white fs-2`} style={{marginRight: 10}}></i>}
        loading={totalDonationMemberLoading}
      />
      <MiniCardComponent
        title='Target Donasi Ramadhan'
        color='success'
        value={generateCurrencyFormat(10000000)}
        icon={<i className={`bi bi-bar-chart-line text-white fs-2`} style={{marginRight: 10}}></i>}
        loading={totalDonationMemberLoading}
      />
      <MiniCardComponent
        title='Target Donasi Tahunan'
        color='success'
        value={generateCurrencyFormat(300000000)}
        icon={<i className={`bi bi-bar-chart-line text-white fs-2`} style={{marginRight: 10}}></i>}
        loading={totalDonationMemberLoading}
      />
    </>
  )
}

export default TotalDonationAffiliatorCardComponent
