import {useEffect} from 'react'
import ReactPaginate from 'react-paginate'
import {KTCard, KTSVG} from '../../../../../../_metronic/helpers'
import {FormAction} from '../../../../../interfaces/form-action-interfaces'
import {useAuth} from '../../../../auth'
import {LoadingComponent} from '../../properties-management/components/loading.component'
import {useCustomerFormStore, useCustomerStore} from '../states/customer.state'
import CustomerModalComponent from './customer-modal.component'

const CustomerAffiliateListComponent = () => {
  const {currentUser} = useAuth()
  const {
    customerByAffiliatorLoading,
    customerHeaders,
    customerByAffiliatorItems,
    customerByAffiliatorModel,
    getCustomerByAffiliator,

    onDelete,
    navList,
    activeNavIndex,
    setActiveNavIndex,
    moveToQuarantine,
    moveToUnquarantine,
  } = useCustomerStore()
  const {field, setField, generateForms, submitDone, formLoading} = useCustomerFormStore()

  useEffect(() => {
    getCustomerByAffiliator(
      {
        page: 0,
        size: 10,
        reference: navList[activeNavIndex].reference,
      },
      currentUser?.data?.id
    )
  }, [])

  useEffect(() => {
    getCustomerByAffiliator(
      {
        page: 0,
        size: 10,
        reference: navList[activeNavIndex].reference,
      },
      currentUser?.data?.id
    )
  }, [activeNavIndex])

  useEffect(() => {
    if (submitDone === true && formLoading === false) {
      getCustomerByAffiliator(
        {
          page: 0,
          size: 10,
          reference: navList[activeNavIndex].reference,
        },
        currentUser?.data?.id
      )
    }
  }, [submitDone, formLoading])

  useEffect(() => {
    if (field === null) {
      generateForms({
        title: 'Create New Donatur',
        subtitle: 'Create New Donatur',
        action: FormAction.CREATE,
        referenceTable: navList[activeNavIndex].reference,
      })
    }

    if (field !== undefined && field !== null) {
      generateForms({
        title: 'Update Donatur',
        subtitle: 'Update Donatur',
        action: FormAction.UPDATE,
        data: field,
        id: field.id,
        referenceTable: navList[activeNavIndex].reference,
      })
    }
  }, [field])

  const handlePageClick = (e: any) => {
    const selectedPage = e.selected
    getCustomerByAffiliator(
      {
        page: 0,
        size: 10,
        reference: navList[activeNavIndex].reference,
      },
      currentUser?.data?.id
    )
  }

  useEffect(() => {
    console.log(customerHeaders)
  }, [customerHeaders])

  return (
    <>
      <div className='card' style={{marginBottom: 10}}>
        <div className='card-header border-0'>
          <div className='d-flex overflow-auto h-55px'>
            <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
              {navList.map((item, index) => {
                return (
                  <li key={index} className='nav-item'>
                    <a
                      className={`nav-link text-active-primary me-6 ${
                        activeNavIndex === index ? 'active' : 'false'
                      }`}
                      href='#'
                      onClick={(e) => {
                        e.preventDefault()
                        setActiveNavIndex(index)
                      }}
                    >
                      {item.name}
                    </a>
                  </li>
                )
              })}
            </ul>
          </div>
        </div>
      </div>
      <KTCard>
        <div className='card-header border-0 pt-6'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>
              Donatur {navList[activeNavIndex].name}
            </span>
            <span className='text-muted mt-1 fw-semibold fs-7'>
              Donatur {navList[activeNavIndex].name} Management
            </span>
          </h3>
          <div className='card-toolbar'>
            {currentUser?.data?.role?.slug === 'direksi' ||
            currentUser?.data?.role?.slug === 'admin' ||
            currentUser?.data?.role?.slug === 'superadmin' ||
            currentUser?.data?.role?.slug === 'korca' ||
            currentUser?.data?.role?.slug === 'bendahara' ? (
              <a
                type='button'
                className='btn btn-primary'
                href={`${process.env.REACT_APP_API_CRM_URL}/public/export-customers?company_id=${currentUser?.data?.company?.id}&reference=${navList[activeNavIndex].reference}`}
                target='_blank'
                rel='noreferrer'
              >
                <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
                Export Excel
              </a>
            ) : (
              <></>
            )}
            {currentUser?.data?.role?.slug === 'direksi' ||
            currentUser?.data?.role?.slug === 'admin' ||
            currentUser?.data?.role?.slug === 'superadmin' ||
            currentUser?.data?.role?.slug === 'bendahara' ||
            currentUser?.data?.role?.slug === 'korca' ||
            currentUser?.data?.role?.slug === 'bendahara' ? (
              <button
                type='button'
                className='btn btn-primary'
                style={{
                  marginLeft: 5,
                }}
                onClick={(e) => {
                  e.preventDefault()
                  setField(null)
                }}
              >
                <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
                Create New Donatur
              </button>
            ) : (
              <></>
            )}
          </div>
        </div>
        <div className='card-body py-3'>
          <div className='table-responsive'>
            {customerByAffiliatorLoading ? <LoadingComponent /> : <></>}
            <table className='table table-row-bordered table-striped table-hover able-row-gray-100 align-middle gs-3 gy-3'>
              <thead>
                <tr className='text-start text-muted fw-bold fs-7 text-uppercase gs-0'>
                  {customerHeaders?.map((item, index) => {
                    return (
                      <th
                        key={item.headerId}
                        className={item.headerId === 'action' ? 'text-end' : ''}
                      >
                        {item.headerName}
                      </th>
                    )
                  })}
                </tr>
              </thead>
              <tbody className='text-gray-600 fw-semibold'>
                {customerByAffiliatorItems.length === 0 ? (
                  <tr>
                    <td colSpan={customerHeaders?.length}>
                      <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                        No Properties Found
                      </div>
                    </td>
                  </tr>
                ) : (
                  customerByAffiliatorItems?.map((item, index) => {
                    return (
                      <tr key={index}>
                        {item.values.map((v, k) => {
                          if (customerHeaders.findIndex((el) => el.headerId === v.name) !== -1) {
                            return <td key={k}>{v.value}</td>
                          }
                          return <></>
                        })}
                        {currentUser?.data?.role?.slug === 'direksi' ||
                        currentUser?.data?.role?.slug === 'admin' ||
                        currentUser?.data?.role?.slug === 'superadmin' ||
                        currentUser?.data?.role?.slug === 'bendahara' ||
                        currentUser?.data?.role?.slug === 'korca' ||
                        currentUser?.data?.role?.slug === 'bendahara' ? (
                          <td>
                            <button
                              className={`btn btn-sm btn-${
                                item.is_quarantine ? 'warning' : 'success'
                              }`}
                              onClick={(e) => {
                                if (item.is_quarantine) {
                                  moveToUnquarantine(item.id, {
                                    size: 10,
                                    page: 0,
                                    reference: navList[activeNavIndex].reference,
                                  })
                                } else {
                                  moveToQuarantine(item.id, {
                                    size: 10,
                                    page: 0,
                                    reference: navList[activeNavIndex].reference,
                                  })
                                }
                              }}
                            >
                              {item.is_quarantine ? 'Yes' : 'No'}
                            </button>
                          </td>
                        ) : (
                          ''
                        )}
                        {currentUser?.data?.role?.slug === 'direksi' ||
                        currentUser?.data?.role?.slug === 'admin' ||
                        currentUser?.data?.role?.slug === 'superadmin' ||
                        currentUser?.data?.role?.slug === 'bendahara' ||
                        currentUser?.data?.role?.slug === 'korca' ||
                        currentUser?.data?.role?.slug === 'bendahara' ? (
                          <td className='text-end'>
                            <a
                              href={`#`}
                              className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                              onClick={(e) => {
                                e.preventDefault()
                                setField(item)
                              }}
                            >
                              <KTSVG
                                path='/media/icons/duotune/art/art005.svg'
                                className='svg-icon-3'
                              />
                            </a>
                            <a
                              href='#'
                              className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                              onClick={(e) => {
                                e.preventDefault()
                                onDelete(item.id, {
                                  page: 0,
                                  size: 10,
                                  reference: navList[activeNavIndex].reference,
                                })
                              }}
                            >
                              <KTSVG
                                path='/media/icons/duotune/general/gen027.svg'
                                className='svg-icon-3'
                              />
                            </a>
                          </td>
                        ) : (
                          ''
                        )}
                      </tr>
                    )
                  })
                )}
              </tbody>
            </table>
          </div>

          <div className='card-footer'>
            <ReactPaginate
              previousLabel={'«'}
              nextLabel={'»'}
              breakLabel={'...'}
              breakClassName={'page-item'}
              breakLinkClassName={'page-link'}
              previousClassName={'page-item'}
              previousLinkClassName={'page-link'}
              nextClassName={'page-item'}
              nextLinkClassName={'page-link'}
              pageCount={customerByAffiliatorModel?.data?.total_pages}
              marginPagesDisplayed={2}
              pageRangeDisplayed={10}
              onPageChange={handlePageClick}
              containerClassName={'pagination pagination-sm m-0 float-right'}
              pageClassName={'page-item'}
              pageLinkClassName={'page-link'}
              activeClassName={'active'}
            />
          </div>
        </div>
      </KTCard>

      {field !== undefined && <CustomerModalComponent />}
    </>
  )
}

const CustomerAffiliateListWrapper = () => {
  return (
    <>
      <CustomerAffiliateListComponent />
    </>
  )
}

export default CustomerAffiliateListWrapper
