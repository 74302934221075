import { useEffect } from "react";
import { useDashboardStore } from "../states/dashboard.state";
import DashboardCardComponent from "./dashboard-card.component";

const TotalRoomCardComponent = () => {

  const {
    totalNewCase, 
    totalOpenCase,
    totalCloseCase,
    totalRoomsLoading,
    getTotalRoom
  } = useDashboardStore();

  useEffect(() => {
    getTotalRoom();
  }, [])

  return (
    <>
      <DashboardCardComponent
        title="Total New Case"
        color="danger"
        value={totalNewCase ?? 0}
        // icon={<i className={`fas fa-gift text-success fs-2`} style={{ marginRight: 10 }}></i>}
        subtitle="Jumlah Case Baru"
        loading={totalRoomsLoading}
      />
      <DashboardCardComponent
        title="Total Open Case"
        color="warning"
        value={totalOpenCase ?? 0}
        // icon={<i className={`fas fa-gift text-success fs-2`} style={{ marginRight: 10 }}></i>}
        subtitle="Jumlah Case Baru"
        loading={totalRoomsLoading}
      />
      <DashboardCardComponent
        title="Total Close Case"
        color="success"
        value={totalCloseCase ?? 0}
        // icon={<i className={`fas fa-gift text-success fs-2`} style={{ marginRight: 10 }}></i>}
        subtitle="Jumlah Case Baru"
        loading={totalRoomsLoading}
      />
    </>
  );
}

export default TotalRoomCardComponent;