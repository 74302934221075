import {CustomerData, CustomerResultInterface} from '../interfaces/customer-result.interface'
import {
  DeleteCustomer,
  GetAllCustomer,
  GetAllCustomerParam,
  GetAllCustomerAffiliate,
  MoveToQuarantine,
  MoveToUnquarantine,
  SubmitCustomer,
  UpdateCustomer,
  GetCustomerByAffiliator,
} from '../providers/customer.provider'
import create from 'zustand'
import {TableHeaderInterface} from '../../../../../interfaces/table-header.interface'
import {
  FormAction,
  FormInput,
  FormProps,
  SelectData,
} from '../../../../../interfaces/form-action-interfaces'
import {ShowFieldByReference} from '../../properties-management/providers/field.provider'
import {Field, FieldData} from '../../properties-management/interfaces/field-result.interface'
import {CustomerSingleResultInterface} from '../interfaces/customer-single-result.interface'
import {toast} from 'react-toastify'
import {
  generateFormLogic,
  generateValueLogic,
} from '../../../../../../_metronic/helpers/generate-form-logic'

export interface CustomerState {
  customerLoading: boolean
  getCustomers: (params: GetAllCustomerParam) => void
  getCustomersAffiliate: (params: GetAllCustomerParam) => void
  customerItems: CustomerData[]
  customerSelectDatas: SelectData[]
  customerModel?: CustomerResultInterface
  customerHeaders: TableHeaderInterface[]
  customerColumns: any[]
  onDelete: (id: string, params: GetAllCustomerParam) => void
  moveToQuarantine: (id: string, params: GetAllCustomerParam) => void
  moveToUnquarantine: (id: string, params: GetAllCustomerParam) => void
  navList: NavList[]
  activeNavIndex: number
  setActiveNavIndex: (index: number) => void

  customerByAffiliatorLoading: boolean
  customerByAffiliatorItems: CustomerData[]
  customerByAffiliatorModel?: CustomerResultInterface
  getCustomerByAffiliator: (params: GetAllCustomerParam, id: string) => void
}

interface NavList {
  name: string
  reference: string
}

export const useCustomerStore = create<CustomerState>((set, get) => ({
  customerLoading: false,
  customerItems: [],
  customerModel: undefined,
  customerHeaders: [],
  customerColumns: [],
  customerSelectDatas: [],
  activeNavIndex: 0,
  setActiveNavIndex: (index: number) => {
    set({activeNavIndex: index})
  },
  navList: [
    {
      name: 'Retails',
      reference: 'customers',
    },
    {
      name: 'Corporate',
      reference: 'customers_corporate',
    },
    {
      name: 'Community',
      reference: 'customers_community',
    },
  ],
  setNavList: () => {},
  getAllCustomers: async () => {
    set({customerLoading: true})
  },
  getCustomers: async (params: GetAllCustomerParam) => {
    set({customerLoading: true})
    const propertiesRes = await ShowFieldByReference(params.reference)
    const response = await GetAllCustomer(params)
    set({customerModel: response})

    let headers: TableHeaderInterface[] = []
    let columns = []
    let singleColumn = {}

    if (propertiesRes) {
      if (propertiesRes.status) {
        if (propertiesRes.data.length > 0) {
          // set header
          for (const item of propertiesRes.data) {
            if (item.fields.is_header) {
              headers.push({
                headerName: item.fields.title,
                headerId: item.fields.name,
                hidden: false,
              })
            }
          }
          // headers.push({
          //   headerName: 'Quarantine',
          //   headerId: 'quarantine',
          //   hidden: false,
          // })
          // headers.push({
          //   headerName: 'Action',
          //   headerId: 'action',
          //   hidden: false,
          // })
          set({
            customerHeaders: headers,
          })
        }
      }
    }

    if (response) {
      if (response.status) {
        if (response.data.items.length > 0) {
          set({customerItems: response.data.items})

          // set select data
          let transformDatas: SelectData[] = []

          // set columns
          for (const item of response.data.items) {
            let customerName = '',
              customerNid = ''
            for (const iitem of item.values) {
              if (iitem.name === 'nid') {
                customerNid = iitem.value as string
              }
              if (iitem.name === 'nama-lengkap') {
                customerName = iitem.value as string
                // transformDatas.push({
                //   value: item.id,
                //   label: iitem.value as string,
                // })
              }
              if (iitem.name === 'nama-perusahaan') {
                customerName = iitem.value as string
                // transformDatas.push({
                //   value: item.id,
                //   label: iitem.value as string,
                // })
              }
              if (iitem.name === 'nama-komunitas') {
                customerName = iitem.value as string
                // transformDatas.push({
                //   value: item.id,
                //   label: iitem.value as string,
                // })
              }

              singleColumn['fieldId'] = item.id
              const value = item.values.find((el) => el.id === iitem.id)
              if (value) {
                singleColumn[value.name] = value.value
              }
            }
            transformDatas.push({
              value: item.id,
              label: `${customerName} - ${customerNid}`,
            })
            columns.push(singleColumn)
          }

          set({
            customerColumns: columns,
            customerSelectDatas: transformDatas,
          })
        } else {
          set({customerItems: [], customerSelectDatas: []})
        }
      } else {
        set({customerItems: [], customerSelectDatas: []})
      }
    } else {
      set({customerItems: [], customerSelectDatas: []})
    }
    set({customerLoading: false})
  },
  getCustomersAffiliate: async (params: GetAllCustomerParam) => {
    set({customerLoading: true})
    const propertiesRes = await ShowFieldByReference(params.reference)
    const response = await GetAllCustomerAffiliate(params)
    set({customerModel: response})

    let headers: TableHeaderInterface[] = []
    let columns = []
    let singleColumn = {}

    if (propertiesRes) {
      if (propertiesRes.status) {
        if (propertiesRes.data.length > 0) {
          // set header
          for (const item of propertiesRes.data) {
            if (item.fields.is_header) {
              headers.push({
                headerName: item.fields.title,
                headerId: item.fields.name,
                hidden: false,
              })
            }
          }
          headers.push({
            headerName: 'Quarantine',
            headerId: 'quarantine',
            hidden: false,
          })
          headers.push({
            headerName: 'Action',
            headerId: 'action',
            hidden: false,
          })
          set({
            customerHeaders: headers,
          })
        }
      }
    }

    if (response) {
      if (response.status) {
        if (response.data.items.length > 0) {
          set({customerItems: response.data.items})

          // set select data
          let transformDatas: SelectData[] = []

          // set columns
          for (const item of response.data.items) {
            let customerName = '',
              customerNid = ''
            for (const iitem of item.values) {
              if (iitem.name === 'nid') {
                customerNid = iitem.value as string
              }
              if (iitem.name === 'nama-lengkap') {
                customerName = iitem.value as string
                // transformDatas.push({
                //   value: item.id,
                //   label: iitem.value as string,
                // })
              }
              if (iitem.name === 'nama-perusahaan') {
                customerName = iitem.value as string
                // transformDatas.push({
                //   value: item.id,
                //   label: iitem.value as string,
                // })
              }
              if (iitem.name === 'nama-komunitas') {
                customerName = iitem.value as string
                // transformDatas.push({
                //   value: item.id,
                //   label: iitem.value as string,
                // })
              }

              singleColumn['fieldId'] = item.id
              const value = item.values.find((el) => el.id === iitem.id)
              if (value) {
                singleColumn[value.name] = value.value
              }
            }
            transformDatas.push({
              value: item.id,
              label: `${customerName} - ${customerNid}`,
            })
            columns.push(singleColumn)
          }

          set({
            customerColumns: columns,
            customerSelectDatas: transformDatas,
          })
        } else {
          set({customerItems: [], customerSelectDatas: []})
        }
      } else {
        set({customerItems: [], customerSelectDatas: []})
      }
    } else {
      set({customerItems: [], customerSelectDatas: []})
    }
    set({customerLoading: false})
  },
  onDelete: async (id: string, params: GetAllCustomerParam) => {
    const confirm = window.confirm('Apakah anda yakin ingin menghapus data ini?')
    if (confirm === true) {
      set({customerLoading: true})
      const response = await DeleteCustomer(id)
      if (response.status) {
        toast.success(response.message)
        get().getCustomers(params)
      } else {
        toast.error(response.message)
      }
    }
  },
  moveToQuarantine: async (id: string, params: GetAllCustomerParam) => {
    set({customerLoading: true})
    const response = await MoveToQuarantine(id)
    if (response.status) {
      toast.success(response.message)
      get().getCustomers(params)
    } else {
      toast.error(response.message)
    }
    set({customerLoading: false})
  },
  moveToUnquarantine: async (id: string, params: GetAllCustomerParam) => {
    set({customerLoading: true})
    const response = await MoveToUnquarantine(id)
    if (response.status) {
      toast.success(response.message)
      get().getCustomers(params)
    } else {
      toast.error(response.message)
    }
    set({customerLoading: false})
  },

  //Get customers
  customerByAffiliatorLoading: false,
  customerByAffiliatorItems: [],
  customerByAffiliatorModel: undefined,
  getCustomerByAffiliator: async (params: GetAllCustomerParam, id: string) => {
    set({customerByAffiliatorLoading: true})
    const propertiesRes = await ShowFieldByReference(params.reference)
    const response = await GetCustomerByAffiliator(params, id)
    set({customerByAffiliatorModel: response})

    let headers: TableHeaderInterface[] = []
    let columns = []
    let singleColumn = {}

    if (propertiesRes) {
      if (propertiesRes.status) {
        if (propertiesRes.data.length > 0) {
          // set header
          for (const item of propertiesRes.data) {
            if (item.fields.is_header) {
              headers.push({
                headerName: item.fields.title,
                headerId: item.fields.name,
                hidden: false,
              })
            }
          }

          set({
            customerHeaders: headers,
          })
        }
      }
    }

    if (response) {
      if (response.status) {
        if (response.data.items.length > 0) {
          set({customerByAffiliatorItems: response.data.items})

          // set select data
          let transformDatas: SelectData[] = []

          // set columns
          for (const item of response.data.items) {
            let customerName = '',
              customerNid = ''
            for (const iitem of item.values) {
              if (iitem.name === 'nid') {
                customerNid = iitem.value as string
              }
              if (iitem.name === 'nama-lengkap') {
                customerName = iitem.value as string
                // transformDatas.push({
                //   value: item.id,
                //   label: iitem.value as string,
                // })
              }
              if (iitem.name === 'nama-perusahaan') {
                customerName = iitem.value as string
                // transformDatas.push({
                //   value: item.id,
                //   label: iitem.value as string,
                // })
              }
              if (iitem.name === 'nama-komunitas') {
                customerName = iitem.value as string
                // transformDatas.push({
                //   value: item.id,
                //   label: iitem.value as string,
                // })
              }

              singleColumn['fieldId'] = item.id
              const value = item.values.find((el) => el.id === iitem.id)
              if (value) {
                singleColumn[value.name] = value.value
              }
            }
            transformDatas.push({
              value: item.id,
              label: `${customerName} - ${customerNid}`,
            })
            columns.push(singleColumn)
          }
        } else {
          set({customerByAffiliatorItems: []})
        }
      } else {
        set({customerByAffiliatorItems: []})
      }
    } else {
      set({customerByAffiliatorItems: []})
    }
    set({customerByAffiliatorLoading: false})
  },
}))

export interface CustomerFormState {
  forms?: FormInput[]
  setForms?: (index: number, value: any) => void
  generateForms: (formParam: FormProps) => void
  formParam?: FormProps
  reset: () => void
  field?: CustomerData
  setField?: (field: CustomerData) => void
  formLoading?: boolean
  submitDone?: boolean
  onSubmit: (reference: string) => void
  fieldItems: FieldData[]
}

const formInitialState = {
  forms: [],
  formParam: undefined,
  formLoading: undefined,
  submitDone: undefined,
}

export const useCustomerFormStore = create<CustomerFormState>((set, get) => ({
  forms: [],
  fieldItems: [],
  field: undefined,
  setField: (field: CustomerData) => {
    set({field: field})
  },
  formParam: undefined,
  setForms: (index: number, value: any) => {
    const forms = [...get().forms]
    forms[index] = {
      ...forms[index],
      value: value,
    }
    set({forms: forms})
  },
  generateForms: async (formParam: FormProps) => {
    let forms: FormInput[] = []
    set({forms: []})
    const response = await ShowFieldByReference(formParam.referenceTable)
    if (response) {
      if (response.data.length > 0) {
        set({fieldItems: response.data})
        const fields = get().field
        for (const item of response.data) {
          // if (item.fields.name === 'nid') {
          //   forms.push({
          //     id: item.id,
          //     title: item.fields.title,
          //     placeholder: item.fields.placeholder,
          //     type: item.fields.type,
          //     name: item.fields.name,
          //     value: undefined,
          //     selectData: item.fields.select_data,
          //     disabled: true,
          //     required: item.fields.required,
          //     hidden: false,
          //   })
          // } else
          if (fields === null) {
            forms.push({
              id: item.id,
              title: item.fields.title,
              placeholder: item.fields.placeholder,
              type: item.fields.type,
              name: item.fields.name,
              value: undefined,
              selectData: item.fields.select_data,
              disabled: formParam.action === FormAction.VIEW ? true : false,
              required: item.fields.required,
            })
          } else {
            const fieldIndex = fields.values.findIndex((el) => el.name === item.fields?.name)
            if (fieldIndex > -1) {
              forms.push({
                id: item.id,
                title: item.fields.title,
                placeholder: item.fields.placeholder,
                type: item.fields.type,
                name: item.fields.name,
                value: generateValueLogic(item.fields.type, fields.values[fieldIndex].value),
                selectData: item.fields.select_data,
                disabled:
                  formParam.action === FormAction.VIEW
                    ? true
                    : item.fields?.name === 'nid'
                    ? true
                    : false,
                required: item.fields.required,
              })
            } else {
              forms.push({
                id: item.id,
                title: item.fields.title,
                placeholder: item.fields.placeholder,
                type: item.fields.type,
                name: item.fields.name,
                value: undefined,
                selectData: item.fields.select_data,
                disabled: formParam.action === FormAction.VIEW ? true : false,
                required: item.fields.required,
              })
            }
          }
        }
      }
      set({forms: forms, formParam: formParam})
      return
    }
  },
  reset: () => {
    set(formInitialState)
    return
  },
  onSubmit: async (reference: string) => {
    set({formLoading: true, submitDone: false})

    if (get().fieldItems?.length === 0) {
      return toast.error('Mohon isi field yang diperlukan')
    }
    if (get().forms?.length === 0) {
      return toast.error('Mohon isi field yang diperlukan')
    }

    // check required field
    let requiredError = false

    for (const item of get().forms) {
      if (item.required === true && (item.value === '' || item.value === undefined)) {
        toast.error(`${item.title} is required`)
        requiredError = true
      }
    }

    if (requiredError === true) return

    let values: Field[] = []
    const fields = get().fieldItems
    for (const item of fields) {
      const fieldIndex = get().forms.findIndex((el) => el.id === item.id)
      if (fieldIndex > -1) {
        values.push({
          id: item.id,
          title: item.fields.title,
          name: item.fields.name,
          placeholder: item.fields.placeholder,
          type: item.fields.type,
          select_data: item.fields.select_data,
          required: item.fields.required,
          is_header: item.fields.is_header,
          value: generateFormLogic(item.fields.type, get().forms[fieldIndex].value),
        })
      }
    }

    const form = {
      customer_type: reference,
      values: values,
    }

    let res: CustomerSingleResultInterface = {}
    if (get().formParam.action === FormAction.CREATE) res = await SubmitCustomer(form)
    if (get().formParam.action === FormAction.UPDATE)
      res = await UpdateCustomer(get().formParam?.id, form)

    console.log(res)
    if (res.status) {
      toast.success(res.message)
      set({submitDone: true, formLoading: false, field: undefined})
      return
    } else {
      toast.error(res.message)
      set({submitDone: false, formLoading: false})
      return
    }
  },
}))
