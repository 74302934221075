/* eslint-disable react/jsx-no-target-blank */
import {useIntl} from 'react-intl'
import {useAuth} from '../../../../../app/modules/auth'
import SidebarMenuMainRelawan from './SidebarMenuMainRelawan'
import SidebarMenuMainKorca from './SidebarMenuMainKorca'
import SidebarMenuMainBendahara from './SidebarMenuMainBendahara'
import SidebarMenuMainAdmin from './SidebarMenuMainAdmin'
import SidebarMenuMainDireksi from './SidebarMenuMainDireksi'

const SidebarMenuMain = () => {
  const intl = useIntl()
  const {currentUser} = useAuth()

  return (
    <>
      {currentUser?.data?.role?.slug === 'relawan' ? <SidebarMenuMainRelawan /> : <></>}
      {currentUser?.data?.role?.slug === 'korca' ? <SidebarMenuMainKorca /> : <></>}
      {currentUser?.data?.role?.slug === 'direksi' ? <SidebarMenuMainDireksi /> : <></>}
      {currentUser?.data?.role?.slug === 'bendahara' ? <SidebarMenuMainBendahara /> : <></>}
      {currentUser?.data?.role?.slug === 'superadmin' ? <SidebarMenuMainAdmin /> : <></>}
      {currentUser?.data?.role?.slug === 'admin' ? <SidebarMenuMainAdmin /> : <></>}
    </>
  )
}

export {SidebarMenuMain}
