import {useEffect} from 'react'
import {generateCurrencyFormat} from '../../../../../helpers/generate-currency-format'
import MiniCardComponent from '../../../auth/affiliator-management/components/mini-card-dashboard.component'
import DashboardCardAffiliatorComponent from '../../../auth/affiliator-management/components/dashboard-card-affiliator.component'
import {useDashboardAffiliateStore} from '../states/program-affiliate.state'
import DonationSegmentChart from '../../../auth/affiliator-management/components/donation-chart.component'
import DonationPieChart from './donation-chart.component'
import {useAuth} from '../../../../auth'
import useCopyToClipboard from './copytToClipBoard'
import {toast} from 'react-toastify'
import {KTSVG} from '../../../../../../_metronic/helpers'
import {useKorcaStore} from '../../../crm/korca-management/states/korca-management.state'
import DonationListAffiliatorComponent from './donation-affiliator-list.component'
import CustomerAffiliatorListWrapper from './customer-list-affilate.component'
import GenerateLinkListWrapper from './genarete-link-list.component'
import {KorcaMemberListWrapper} from '../../../crm/korca-management/components/korca-group-detail.component'
import {useCustomerStore} from '../../../crm/customer-management/states/customer.state'
import {useAffiliatorCompanyStore} from '../../../auth/affiliator-management/states/affiliator-state'

const DashboardAffiliatorComponent = () => {
  const [value, copy] = useCopyToClipboard()
  const {
    totalDonationAffiliateLoading,
    getTotalDonationAffiliateByTransfer,
    totalDonationAffiliateByTransfer,
    totalDonationAffiliateByTransferLoading,
    totalDonationAffiliateByTunai,
    totalDonationAffiliateByTunaiLoading,
    getTotalDonationAffiliateByTunai,

    getTotalDonationByKorca,
    totalDonationByKorca,
    totalDonationByKorcaLoading,

    getTotalDonationByKorcaTunai,
    totalDonationByKorcaTunai,
    totalDonationByKorcaTunaiLoading,

    getTotalDonationByKorcaTransfer,
    totalDonationByKorcaTransfer,
    totalDonationByKorcaTransferLoading,
  } = useDashboardAffiliateStore()

  const {getKorcaGroup, korcaGroupItems, korcaGroupLoading} = useKorcaStore()

  const {currentUser} = useAuth()
  const {
    getCustomerByAffiliator,
    customerByAffiliatorModel,
    customerByAffiliatorLoading,
    navList,
    activeNavIndex,
  } = useCustomerStore()
  const {totalDonationMember, getTotalDoantionMember, totalDonationMemberLoading} =
    useAffiliatorCompanyStore()

  useEffect(() => {
    getTotalDonationByKorca()
    getTotalDonationByKorcaTunai()
    getTotalDonationByKorcaTransfer()
  }, [])

  useEffect(() => {
    getTotalDoantionMember(currentUser?.data?.id)
  }, [])

  useEffect(() => {
    getTotalDonationAffiliateByTransfer(currentUser?.data?.id)
  }, [])

  useEffect(() => {
    getTotalDonationAffiliateByTunai(currentUser?.data?.id)
  }, [])

  useEffect(() => {
    getCustomerByAffiliator(
      {
        page: 0,
        size: 10,
        reference: navList[activeNavIndex].reference,
      },
      currentUser?.data?.id
    )
  }, [])

  useEffect(() => {
    if (currentUser) {
      getKorcaGroup(currentUser?.data?.id)
    }
  }, [currentUser])

  return (
    <>
      <div className='row gy-5 g-xl-8 mb-5'>
        {currentUser?.data?.role?.slug === 'korca' ? (
          <DashboardCardAffiliatorComponent
            title='Total Donation Seluruh Group'
            color='success'
            value={generateCurrencyFormat(totalDonationByKorca ?? 0)}
            subtitle='Total donasi yang terkumpul'
            loading={totalDonationByKorcaLoading}
          />
        ) : (
          <DashboardCardAffiliatorComponent
            title='Total Donation '
            color='success'
            value={generateCurrencyFormat(
              totalDonationMember +
                totalDonationAffiliateByTransfer +
                totalDonationAffiliateByTunai ?? 0
            )}
            subtitle='Total donasi yang terkumpul'
            loading={totalDonationAffiliateLoading}
          />
        )}

        {currentUser?.data?.role?.slug === 'bendahara' ||
        currentUser?.data?.role?.slug === 'korca' ? (
          <DashboardCardAffiliatorComponent
            title='Total Group'
            color='warning'
            value={korcaGroupItems?.group_work?.length ?? 0}
            subtitle='Total donatur'
            loading={korcaGroupLoading}
          />
        ) : (
          <DashboardCardAffiliatorComponent
            title='Total Donatur'
            color='warning'
            value={customerByAffiliatorModel?.data?.total ?? 0}
            subtitle='Total donatur'
            loading={customerByAffiliatorLoading}
          />
        )}

        {currentUser?.data?.role?.slug === 'bendahara' ||
        currentUser?.data?.role?.slug === 'korca' ? (
          <MiniCardComponent
            title='Transfer'
            color='primary'
            value={generateCurrencyFormat(totalDonationByKorcaTransfer ?? 0)}
            icon={
              <i className={`bi bi-send-check-fill text-white fs-2`} style={{marginRight: 10}}></i>
            }
            loading={totalDonationByKorcaTransferLoading}
          />
        ) : (
          <MiniCardComponent
            title='Transfer'
            color='primary'
            value={generateCurrencyFormat(totalDonationAffiliateByTransfer ?? 0)}
            icon={
              <i className={`bi bi-send-check-fill text-white fs-2`} style={{marginRight: 10}}></i>
            }
            loading={totalDonationAffiliateByTransferLoading}
          />
        )}

        {currentUser?.data?.role?.slug === 'bendahara' ||
        currentUser?.data?.role?.slug === 'korca' ? (
          <MiniCardComponent
            title='Tunai'
            color='warning'
            value={generateCurrencyFormat(totalDonationByKorcaTunai ?? 0)}
            icon={<i className={`bi bi-cash-coin text-white fs-2`} style={{marginRight: 10}}></i>}
            loading={totalDonationByKorcaTunaiLoading}
          />
        ) : (
          <MiniCardComponent
            title='Tunai'
            color='warning'
            value={generateCurrencyFormat(totalDonationAffiliateByTunai ?? 0)}
            icon={<i className={`bi bi-cash-coin text-white fs-2`} style={{marginRight: 10}}></i>}
            loading={totalDonationAffiliateByTunaiLoading}
          />
        )}

        {currentUser?.data?.role?.slug === 'korca' ? (
          <MiniCardComponent
            title='Affiliate'
            color='danger'
            value={generateCurrencyFormat(
              totalDonationByKorca - totalDonationByKorcaTransfer - totalDonationByKorcaTunai ??
                0 ??
                0
            )}
            icon={<i className={`bi bi-share text-white fs-2`} style={{marginRight: 10}}></i>}
            loading={totalDonationByKorcaLoading}
          />
        ) : (
          <MiniCardComponent
            title='Affiliate'
            color='danger'
            value={generateCurrencyFormat(totalDonationMember ?? 0)}
            icon={<i className={`bi bi-share text-white fs-2`} style={{marginRight: 10}}></i>}
            loading={totalDonationMemberLoading}
          />
        )}

        <MiniCardComponent
          title='Target Donasi Ramadhan'
          color='success'
          value={generateCurrencyFormat(10000000)}
          icon={
            <i className={`bi bi-bar-chart-line text-white fs-2`} style={{marginRight: 10}}></i>
          }
          loading={totalDonationAffiliateLoading}
        />
        <MiniCardComponent
          title='Target Donasi Tahunan'
          color='success'
          value={generateCurrencyFormat(300000000)}
          icon={
            <i className={`bi bi-bar-chart-line text-white fs-2`} style={{marginRight: 10}}></i>
          }
          loading={totalDonationAffiliateLoading}
        />
        {currentUser?.data?.role?.slug === 'korca' ? (
          ''
        ) : (
          <div className='card-toolbar text-end'>
            <button
              type='button'
              className='btn btn-danger'
              style={{
                marginLeft: 5,
              }}
              onClick={() => {
                copy(
                  `${currentUser?.data?.company?.platform_donation}?affiliate_code=${currentUser.data.affiliate_code}`
                )
                if (value) {
                  toast.success('copied !')
                }
              }}
            >
              <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
              Create Affiliate Link
            </button>
          </div>
        )}
      </div>
      {currentUser?.data?.role?.slug === 'korca' ? (
        ''
      ) : (
        <div className='mb-5'>
          <GenerateLinkListWrapper />
        </div>
      )}

      <div className='row gy-5  mb-5 '>
        <DonationSegmentChart />
        <DonationPieChart />
      </div>
      {currentUser?.data?.role?.slug === 'korca' ? (
        ''
      ) : (
        <div className='mb-5'>
          <DonationListAffiliatorComponent />
        </div>
      )}
    </>
  )
}

export default DashboardAffiliatorComponent
