import ApexCharts, {ApexOptions} from 'apexcharts'
import { useRef, useEffect, useState } from "react"
import { getCSS, getCSSVariableValue } from "../../../../../_metronic/assets/ts/_utils"
import { dateTimeFormatter, monthFormatter, yearFormatter } from '../../../../../_metronic/helpers/datetime-formatter'
import { useThemeMode } from "../../../../../_metronic/partials"
import { LoadingComponent } from '../../crm/properties-management/components/loading.component'
import { DonationByPeriodeData } from '../../program/donation/interfaces/donation-by-periode-result.interface'
import { useDashboardStore } from '../states/dashboard.state'
import Chart from 'react-apexcharts'

const CustomerSegmentChart = () => {
  const { getCustomerSegment, customerSegmentLoading, customerSegments} = useDashboardStore();
  const [options, setOptions] = useState<ApexCharts.ApexOptions>({});
  const [series, setSeries] = useState<number[]>([]);
  const [labels, setLabels] = useState<string[]>([]);

  useEffect(() => {
    getCustomerSegment();
  }, [])

  useEffect(() => {
    if (customerSegments?.length > 0) {
      setSeries(customerSegments?.map((item) => item.total))
      setLabels(customerSegments?.map((item) => item.segment))
      setOptions({
        labels: customerSegments?.map((item) => item.segment)
      })
    }
  }, [customerSegments])
  
  return (
    <div className='col-xxl-6'>
      <div className="card">
        <div className="card-header border-0 pt-5">
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>Donatur Segmen</span>
            <span className='text-muted fw-semibold fs-7'>Jumlah Donatur pada setiap segmen</span>
          </h3>

          {/* begin::Toolbar */}
          {/* <div className='card-toolbar' data-kt-buttons='true'>
            <a
              className={`btn btn-sm btn-color-muted btn-active btn-active-primary ${periode === 'year' ? 'active' : ''} px-4 me-1`}
              id='kt_charts_widget_3_year_btn'
              onClick={e => {
                e.preventDefault();
                setPeriode('year');
              }}>
              Year
            </a>

            <a
              className={`btn btn-sm btn-color-muted btn-active btn-active-primary ${periode === 'month' ? 'active' : ''} px-4 me-1`}
              id='kt_charts_widget_3_month_btn'
              onClick={e => {
                e.preventDefault();
                setPeriode('month');
              }}>
              Month
            </a>

            <a
              className={`btn btn-sm btn-color-muted btn-active btn-active-primary ${periode === 'week' ? 'active' : ''} px-4`}
              id='kt_charts_widget_3_week_btn'
              onClick={e => {
                e.preventDefault();
                setPeriode('week');
              }}>
              Week
            </a>

            <a
              className={`btn btn-sm btn-color-muted btn-active btn-active-primary ${periode === 'day' ? 'active' : ''} px-4`}
              id='kt_charts_widget_3_week_btn'
              onClick={e => {
                e.preventDefault();
                setPeriode('day');
              }}>
              Day
            </a>
          </div> */}
          {/* end::Toolbar */}
        </div>

        {/* begin::Body */}
        <div className='card-body'>
          {
            customerSegmentLoading
            ? <LoadingComponent/>
            : <></>
          }
          {/* begin::Chart */}
          <Chart 
            options={options} 
            series={series} 
            type="donut" 
            width="380"
          />
          {/* end::Chart */}
        </div>
        {/* end::Body */}
      </div>
    </div>
  );
}

export default CustomerSegmentChart;