import {AxiosError} from 'axios'
import {async} from 'q'
import {Api, ApiCrm, ApiProgram} from '../../../../../api/api'
import {GeneralInterface} from '../../../../../interfaces/general.interface'
import {AffiliatorData, AffiliatorResultInterface} from '../interfaces/affilator-result.interface'
import {CustomerResultInterface} from '../../../crm/customer-management/interfaces/customer-result.interface'
import {FieldResultInterface} from '../../../crm/properties-management/interfaces/field-result.interface'
import {AffiliatorSingleResultInterface} from '../interfaces/affiliator-single-result.interface'
import {UserSingleResultInterface} from '../../user-management/interfaces/user-single-result.interface'
import {UserCompanyData} from '../../user-company-management/interfaces/user-company-result.interface'
import {UserCompanySingleResultInterface} from '../../user-company-management/interfaces/user-company-single-result.interface'

export interface GetAllAffiliatorParam {
  page?: number
  size?: number
  search?: string
  name?: string
  phone?: string
  email?: string
  district_id?: string
}

export interface GetAllCustomerAffiliatorParam {
  page?: number
  size?: number
  reference?: string
}

export interface GetAllCustomerParam {
  page?: number
  size?: number
  reference?: string
  is_quarantine?: boolean
}

export const ShowFieldByReference = async (reference: string): Promise<FieldResultInterface> => {
  try {
    let response = await ApiCrm.get('/fields-by-reference', {
      params: {
        reference_table: reference,
      },
    })
    return response.data as FieldResultInterface
  } catch (e) {
    let a = e as AxiosError
    let response: FieldResultInterface = a.response?.data
    return response
  }
}
export const GetAllCustomerAffiliate = async (
  params: GetAllCustomerParam
): Promise<CustomerResultInterface> => {
  try {
    let response = await ApiCrm.get('/customer-affiliate', {
      params: params,
    })
    return response.data
  } catch (e) {
    let a = e as AxiosError
    let response: CustomerResultInterface = a.response?.data
    return response
  }
}

export const GetAllAffiliator = async (
  params: GetAllAffiliatorParam
): Promise<AffiliatorResultInterface> => {
  try {
    let response = await Api.get('/affiliators-company', {params: params})
    return response.data
  } catch (e) {
    let a = e as AxiosError
    let response: AffiliatorResultInterface = a.response?.data
    return response
  }
}

export const GetTotalDonationMember = async (id: string): Promise<GeneralInterface> => {
  try {
    let response = await ApiProgram.get('/total-donation-affiliator/' + id)
    return response.data as GeneralInterface
  } catch (e) {
    let a = e as AxiosError
    let response: GeneralInterface = a.response?.data
    return response
  }
}

export const GetTotalDonaturMember = async (id: string): Promise<GeneralInterface> => {
  try {
    let response = await ApiCrm.get('/total-customer-affiliator/' + id)
    return response.data as GeneralInterface
  } catch (e) {
    let a = e as AxiosError
    let response: GeneralInterface = a.response?.data
    return response
  }
}

export const GetCustomerByKorca = async (
  params: GetAllCustomerAffiliatorParam
): Promise<CustomerResultInterface> => {
  try {
    let response = await ApiCrm.get('/customers-by-korca', {params: params})
    return response.data
  } catch (e) {
    let a = e as AxiosError
    let response: CustomerResultInterface = a.response?.data
    return response
  }
}

export const SubmitAffiliator = async (form: any): Promise<UserSingleResultInterface> => {
  try {
    let response = await Api.post('users', form)
    return response.data as UserSingleResultInterface
  } catch (e) {
    let a = e as AxiosError
    let response: UserSingleResultInterface = a.response?.data
    return response
  }
}

export const UpdateAffiliator = async (
  id: string,
  form: any
): Promise<UserSingleResultInterface> => {
  try {
    let response = await Api.put('/users/' + id, form)
    return response.data as UserSingleResultInterface
  } catch (e) {
    let a = e as AxiosError
    let response: UserSingleResultInterface = a.response?.data
    return response
  }
}

export const DeleteAffiliator = async (id: string): Promise<GeneralInterface> => {
  try {
    let response = await Api.delete('/users/' + id)
    return response.data as GeneralInterface
  } catch (e) {
    let a = e as AxiosError
    let response: GeneralInterface = a.response?.data
    return response
  }
}
