import ApexCharts, {ApexOptions} from 'apexcharts'
import { useRef, useEffect, useState } from "react"
import { getCSS, getCSSVariableValue } from "../../../../../_metronic/assets/ts/_utils"
import { dateTimeFormatter, monthFormatter, yearFormatter } from '../../../../../_metronic/helpers/datetime-formatter'
import { useThemeMode } from "../../../../../_metronic/partials"
import { generateCurrencyFormat } from '../../../../helpers/generate-currency-format'
import { LoadingComponent } from '../../crm/properties-management/components/loading.component'
import { DonationByPeriodeData } from '../../program/donation/interfaces/donation-by-periode-result.interface'
import { useDashboardStore } from '../states/dashboard.state'

const DonationTrxByPeriodeChartComponent = () => {
  const { getDonationTrxByPeriode, donationTrxByPeriodeLoading, donationTrxByPeriodes} = useDashboardStore();
  const [periode, setPeriode] = useState<string>("year")
  const chartRef = useRef<HTMLDivElement | null>(null)
  const {mode} = useThemeMode()
  const refreshMode = () => {
    if (!chartRef.current) {
      return
    }
    const height = parseInt(getCSS(chartRef.current, 'height'))
    const chart = new ApexCharts(chartRef.current, getChartOptions(height, donationTrxByPeriodes, periode))
    if (chart) {
      chart.render()
    }
    return chart
  }

  useEffect(() => {
    getDonationTrxByPeriode(periode)
  }, [periode])

  useEffect(() => {
    const chart = refreshMode()
    return () => {
      if (chart) {
        chart.destroy()
      }
    }
  }, [chartRef, mode, donationTrxByPeriodes, periode])
  
  return (
    <>
      <div className="card card-xxl-stretch mb-5 mb-xl-10">
        <div className="card-header border-0 pt-5">
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>Pencapaian Transaksi </span>
            <span className='text-muted fw-semibold fs-7'>Pencapaian Transaksi Per Periode</span>
          </h3>

          {/* begin::Toolbar */}
          <div className='card-toolbar' data-kt-buttons='true'>
            <a
              className={`btn btn-sm btn-color-muted btn-active btn-active-primary ${periode === 'year' ? 'active' : ''} px-4 me-1`}
              id='kt_charts_widget_3_year_btn'
              onClick={e => {
                e.preventDefault();
                setPeriode('year');
              }}>
              Year
            </a>

            <a
              className={`btn btn-sm btn-color-muted btn-active btn-active-primary ${periode === 'month' ? 'active' : ''} px-4 me-1`}
              id='kt_charts_widget_3_month_btn'
              onClick={e => {
                e.preventDefault();
                setPeriode('month');
              }}>
              Month
            </a>

            <a
              className={`btn btn-sm btn-color-muted btn-active btn-active-primary ${periode === 'week' ? 'active' : ''} px-4`}
              id='kt_charts_widget_3_week_btn'
              onClick={e => {
                e.preventDefault();
                setPeriode('week');
              }}>
              Week
            </a>

            <a
              className={`btn btn-sm btn-color-muted btn-active btn-active-primary ${periode === 'day' ? 'active' : ''} px-4`}
              id='kt_charts_widget_3_week_btn'
              onClick={e => {
                e.preventDefault();
                setPeriode('day');
              }}>
              Day
            </a>
          </div>
          {/* end::Toolbar */}
        </div>

        {/* begin::Body */}
        <div className='card-body'>
          {
            donationTrxByPeriodeLoading
            ? <LoadingComponent/>
            : <></>
          }
          {/* begin::Chart */}
          <div ref={chartRef} id='kt_charts_widget_3_chart' style={{height: '350px'}}></div>
          {/* end::Chart */}
        </div>
        {/* end::Body */}
      </div>
    </>
  );
}

function getChartOptions(height: number, donationPeriodes: DonationByPeriodeData[], periode: string): ApexOptions {
  const labelColor = getCSSVariableValue('--kt-gray-500')
  const borderColor = getCSSVariableValue('--kt-gray-200')
  const baseColor = getCSSVariableValue('--kt-info')
  const lightColor = getCSSVariableValue('--kt-info-light')

  return {
    series: [
      {
        name: 'Total Transaksi',
        data: donationPeriodes?.map((item) => item.total_amounts),
      },
    ],
    chart: {
      fontFamily: 'inherit',
      type: 'area',
      height: 350,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {},
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    fill: {
      type: 'solid',
      opacity: 1,
    },
    stroke: {
      curve: 'smooth',
      show: true,
      width: 3,
      colors: [baseColor],
    },
    xaxis: {
      categories: donationPeriodes?.map((item) => item.periode),
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
        formatter: function (val) {
          // return '$' + val + ' thousands'
          switch (periode) {
            case 'year':
              return yearFormatter(Date.parse(val))    
            case 'month':
              return monthFormatter(Date.parse(val))    
            case 'week':
              return dateTimeFormatter(Date.parse(val))    
            case 'day':
              return dateTimeFormatter(Date.parse(val))    
          }
        },
      },
      crosshairs: {
        position: 'front',
        stroke: {
          color: baseColor,
          width: 1,
          dashArray: 3,
        },
      },
      tooltip: {
        enabled: true,
        formatter: undefined,
        offsetY: 0,
        style: {
          fontSize: '12px',
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
    },
    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      hover: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none',
          value: 0,
        },
      },
    },
    tooltip: {
      style: {
        fontSize: '12px',
      },
      y: {
        formatter: function (val) {
          // return '$' + val + ' thousands'
          return val.toString();
        },
      },
    },
    colors: [lightColor],
    grid: {
      borderColor: borderColor,
      strokeDashArray: 4,
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
    markers: {
      strokeColors: baseColor,
      strokeWidth: 3,
    },
  }
}

export default DonationTrxByPeriodeChartComponent;