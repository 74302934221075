import {SelectData} from '../../../../../interfaces/form-action-interfaces'
import {ProvinceData, ProvinceResultInterface} from '../interfaces/province-result.interface'
import {GetAllProvince, GetAllProvinceParam} from '../providers/province.provider'
import create from 'zustand'

interface ProvinceState {
  provinceLoading: boolean
  provinceItems: ProvinceData[]
  provinceSelectData: SelectData[]
  provinceModel?: ProvinceResultInterface
  getProvinces: (params: GetAllProvinceParam) => void
  onDelete?: (id: string, params: GetAllProvinceParam) => void
  reset?: () => void
}

export const useProvinceStore = create<ProvinceState>((set, get) => ({
  reset: () => {
    set({
      provinceSelectData: [],
      provinceModel: undefined,
      provinceItems: [],
    })
  },
  provinceLoading: false,
  provinceItems: [],
  provinceSelectData: [],
  provinceModel: undefined,
  getProvinces: async (params: GetAllProvinceParam) => {
    set({provinceLoading: true})
    const response = await GetAllProvince(params)
    if (response.status) {
      if (response.data?.items?.length > 0) {
        let transformDatas: SelectData[] = []
        for (const item of response.data.items) {
          transformDatas.push({
            label: item.name,
            value: item.id,
          })
        }
        set({
          provinceSelectData: transformDatas,
          provinceItems: response.data.items,
        })
      } else {
        set({
          provinceSelectData: [],
          provinceItems: [],
        })
      }
    } else {
      set({
        provinceSelectData: [],
        provinceItems: [],
      })
    }
    set({provinceLoading: false})
  },
  onDelete: (id: string, params: GetAllProvinceParam) => {},
}))
