import create from 'zustand'
import {DonationAffiliateSingleResultInterface} from '../interfaces/program-affiliate-single-resultInterface'
import {generateFormLogic} from '../../../../../../_metronic/helpers/generate-form-logic'
import {
  DonationAffiliateData,
  DonationAffiliateResultInterface,
} from '../interfaces/program-affiliate-resultInterface'

import {
  FormAction,
  FormInput,
  FormProps,
  SelectData,
} from '../../../../../interfaces/form-action-interfaces'

import {
  GetAllDonationAffiliateParam,
  GetAllDonationAffiliate,
  DeleteDonationAffiliate,
  UpdateDonationAffiliate,
  ShowDonationAffiliate,
  SubmitDonationAffiliate,
  GetTotalDonationAffiliate,
  GetTotalCustomer,
  GetTotalDonationAffiliateByTransfer,
  GetTotalDonationAffiliateByTunai,
} from '../providers/program-affiliate.provider'
import {toast} from 'react-toastify'
import moment from 'moment'
import {
  GetTotalDonationByKorca,
  GetTotalDonationByKorcaInDirectionPage,
} from '../../donation/providers/donation.provider'

interface NavList {
  name: string
  reference: string
}

interface DashboardAffilaiateState {
  // Total Donatur
  totalCustomerLoading: boolean
  totalCustomer: number
  getTotalCustomer: () => void

  // Total donation
  totalDonationAffiliateLoading: boolean
  totalDonationAffiliate: number
  getTotalDonationAffiliate: () => {}

  // Total donation by transfer
  totalDonationAffiliateByTransferLoading: boolean
  totalDonationAffiliateByTransfer: number
  getTotalDonationAffiliateByTransfer: (id: string) => {}

  // Total donation by tunai
  totalDonationAffiliateByTunaiLoading: boolean
  totalDonationAffiliateByTunai: number
  getTotalDonationAffiliateByTunai: (id: string) => {}

  // total donation by korca
  totalDonationByKorca?: number
  totalDonationByKorcaLoading?: boolean
  getTotalDonationByKorca?: () => void

  // total donation by korca tunai
  totalDonationByKorcaTunai?: number
  totalDonationByKorcaTunaiLoading?: boolean
  getTotalDonationByKorcaTunai?: () => void

  // total donation by korca transfer
  totalDonationByKorcaTransfer?: number
  totalDonationByKorcaTransferLoading?: boolean
  getTotalDonationByKorcaTransfer?: () => void

  // total donation by korca in direction page
  totalDonationByKorcaInDirectionPage?: number
  totalDonationByKorcaDirectionPageLoading?: boolean
  getTotalDonationByKorcaKorcaInDirectionPage?: (id: string) => void

  // total donation by korca tunai
  totalDonationByKorcaTunaiInDirectionPage?: number
  totalDonationByKorcaTunaiInDirectionPageLoading?: boolean
  getTotalDonationByKorcaTunaiInDirectionPage?: (id: string) => void

  // total donation by korca transfer
  totalDonationByKorcaTransferInDirectionPage?: number
  totalDonationByKorcaTransferInDirectionPageLoading?: boolean
  getTotalDonationByKorcaTransferInDirectionPage?: (id: string) => void
}

export const useDashboardAffiliateStore = create<DashboardAffilaiateState>((set, get) => ({
  totalCustomerLoading: false,
  totalCustomer: 0,
  getTotalCustomer: async () => {
    set({totalCustomerLoading: true})
    const response = await GetTotalCustomer()
    if (response.status) {
      set({totalCustomer: response.data as number})
    } else {
      set({totalCustomer: 0})
    }
    setTimeout(() => {
      set({totalCustomerLoading: false})
    }, 2000)
  },
  totalDonationAffiliateLoading: false,
  totalDonationAffiliate: 0,
  getTotalDonationAffiliate: async () => {
    set({totalDonationAffiliateLoading: true})
    const response = await GetTotalDonationAffiliate()
    if (response.status) {
      set({totalDonationAffiliate: response.data as number})
    } else {
      set({totalDonationAffiliate: 0})
    }
    setTimeout(() => {
      set({totalDonationAffiliateLoading: false})
    }, 2000)
  },

  totalDonationAffiliateByTransferLoading: false,
  totalDonationAffiliateByTransfer: 0,
  getTotalDonationAffiliateByTransfer: async (id: string) => {
    set({totalDonationAffiliateByTransferLoading: true})
    const response = await GetTotalDonationAffiliateByTransfer(id)
    if (response.status) {
      set({totalDonationAffiliateByTransfer: response.data as number})
    } else {
      set({totalDonationAffiliateByTransfer: 0})
    }
    setTimeout(() => {
      set({totalDonationAffiliateByTransferLoading: false})
    }, 2000)
  },

  totalDonationAffiliateByTunaiLoading: false,
  totalDonationAffiliateByTunai: 0,
  getTotalDonationAffiliateByTunai: async (id: string) => {
    set({totalDonationAffiliateByTransferLoading: true})
    const response = await GetTotalDonationAffiliateByTunai(id)
    if (response.status) {
      set({totalDonationAffiliateByTunai: response.data as number})
    } else {
      set({totalDonationAffiliateByTunai: 0})
    }
    setTimeout(() => {
      set({totalDonationAffiliateByTransferLoading: false})
    }, 2000)
  },

  // total donation by korca
  totalDonationByKorcaLoading: false,
  totalDonationByKorca: 0,
  getTotalDonationByKorca: async () => {
    set({totalDonationByKorcaLoading: true})
    const response = await GetTotalDonationByKorca({payment_type: undefined})
    if (response.status) {
      set({totalDonationByKorca: response.data as number})
    } else {
      set({totalDonationByKorca: 0})
    }
    set({totalDonationByKorcaLoading: false})
  },

  // total donation by korca tunai
  InDirectionPage: 0,
  totalDonationByKorcaTunaiLoading: false,
  getTotalDonationByKorcaTunai: async () => {
    set({totalDonationByKorcaTunaiLoading: true})
    const response = await GetTotalDonationByKorca({payment_type: 'tunai'})
    if (response.status) {
      set({totalDonationByKorcaTunai: response.data as number})
    } else {
      set({totalDonationByKorcaTunai: 0})
    }
    set({totalDonationByKorcaTunaiLoading: false})
  },

  // total donation by korca transfer
  totalDonationByKorcaTransfer: 0,
  totalDonationByKorcaTransferLoading: false,
  getTotalDonationByKorcaTransfer: async () => {
    set({
      totalDonationByKorcaTransferLoading: true,
    })
    const response = await GetTotalDonationByKorca({payment_type: 'transfer'})
    if (response.status) {
      set({totalDonationByKorcaTransfer: response.data as number})
    } else {
      set({totalDonationByKorcaTransfer: 0})
    }
    set({totalDonationByKorcaTransferLoading: false})
  },

  // total donation by korca direksi
  totalDonationByKorcaInDirectionPageLoading: false,
  totalDonationByKorcaInDirectionPage: 0,
  getTotalDonationByKorcaKorcaInDirectionPage: async (id: string) => {
    set({totalDonationByKorcaDirectionPageLoading: true})
    const response = await GetTotalDonationByKorcaInDirectionPage(id, {payment_type: undefined})
    if (response.status) {
      set({totalDonationByKorcaInDirectionPage: response.data as number})
    } else {
      set({totalDonationByKorcaInDirectionPage: 0})
    }
    set({totalDonationByKorcaDirectionPageLoading: false})
  },

  // total donation by korca tunai
  totalDonationByKorcaTunaiInDirectionPage: 0,
  totalDonationByKorcaTunaiInDirectionPageLoading: false,
  getTotalDonationByKorcaTunaiInDirectionPage: async (id: string) => {
    set({totalDonationByKorcaTunaiInDirectionPageLoading: true})
    const response = await GetTotalDonationByKorcaInDirectionPage(id, {payment_type: 'tunai'})
    if (response.status) {
      set({totalDonationByKorcaTunaiInDirectionPage: response.data as number})
    } else {
      set({totalDonationByKorcaTunaiInDirectionPage: 0})
    }
    set({totalDonationByKorcaTunaiInDirectionPageLoading: false})
  },

  // total donation by korca transfer
  totalDonationByKorcaTransferInDirectionPage: 0,
  totalDonationByKorcaTransferInDirectionPageLoading: false,
  getTotalDonationByKorcaTransferInDirectionPage: async (id: string) => {
    set({
      totalDonationByKorcaTransferInDirectionPageLoading: true,
    })
    const response = await GetTotalDonationByKorcaInDirectionPage(id, {payment_type: 'transfer'})
    if (response.status) {
      set({totalDonationByKorcaTransferInDirectionPage: response.data as number})
    } else {
      set({totalDonationByKorcaTransferInDirectionPage: 0})
    }
    set({totalDonationByKorcaTransferInDirectionPageLoading: false})
  },
}))

interface DonationAffiliateState {
  donationAffiliateLoading: boolean
  donationAffiliateItems: DonationAffiliateData[]
  donationAffiliateModel?: DonationAffiliateResultInterface
  getDonationAffiliate: (params: GetAllDonationAffiliateParam) => void
  onDelete: (id: string, params: GetAllDonationAffiliateParam) => void
  navList: NavList[]
  activeNavIndex: number
  setActiveNavIndex: (index: number) => void
  selectedDonationAffiliate: DonationAffiliateData
  setSelectedDonationAffiliate: (data: DonationAffiliateData) => void
  getDonationAffiliateDetail: (id: string) => void
}

export const useDonationAffiliateStore = create<DonationAffiliateState>((set, get) => ({
  activeNavIndex: 0,
  setActiveNavIndex: (index: number) => {
    set({activeNavIndex: index})
  },
  navList: [
    {
      name: 'Retails',
      reference: 'customers',
    },
    {
      name: 'Corporate',
      reference: 'customers_corporate',
    },
    {
      name: 'Community',
      reference: 'customers_community',
    },
  ],
  donationAffiliateLoading: false,
  donationAffiliateItems: [],
  donationSelectDatas: [],
  donationAffiliateModel: undefined,
  getDonationAffiliate: async (params: GetAllDonationAffiliateParam) => {
    set({donationAffiliateLoading: true})
    const response = await GetAllDonationAffiliate(params)
    set({donationAffiliateModel: response})
    if (response.status) {
      if (response.data.items.length > 0) {
        let transformDatas: SelectData[] = []
        for (const item of response.data.items) {
          transformDatas.push({
            label: item.id,
            value: item.id,
          })
        }
        set({
          donationAffiliateItems: response.data.items,
        })
      } else {
        set({
          donationAffiliateItems: [],
        })
      }
    } else {
      set({
        donationAffiliateItems: [],
      })
    }
    set({donationAffiliateLoading: false})
  },
  onDelete: async (id: string, params: GetAllDonationAffiliateParam) => {
    const confirm = window.confirm('Apakah anda yakin ingin menghapus data ini?')
    if (confirm === true) {
      set({donationAffiliateLoading: true})
      const response = await DeleteDonationAffiliate(id)
      if (response.status) {
        toast.success(response.message)
      } else {
        toast.error(response.message)
      }
      get().getDonationAffiliate(params)
      set({donationAffiliateLoading: false})
    }
  },
  selectedDonationAffiliate: undefined,
  setSelectedDonationAffiliate: (data: DonationAffiliateData) => {
    set({selectedDonationAffiliate: data})
  },
  getDonationAffiliateDetail: async (id: string) => {
    const response = await ShowDonationAffiliate(id)
    if (response.status) {
      set({selectedDonationAffiliate: response.data})
    }
  },
}))

const formInitialState = {
  loading: false,
  forms: [],
  formParam: undefined,
  formLoading: undefined,
  submitDone: undefined,
}

export interface DonationAffiliateFormState {
  field?: DonationAffiliateData
  setField?: (item: DonationAffiliateData) => void
  forms?: FormInput[]
  setForms?: (index: number, value: any) => void
  generateForms: (formParam: FormProps) => void
  setFormSelectData: (index: number, selectData: SelectData[]) => void
  formParam: FormProps
  reset: () => void
  formLoading?: boolean
  submitDone?: boolean
  onSubmit: (reference: string) => void
}

export const useDonationAffiliateFormStore = create<DonationAffiliateFormState>((set, get) => ({
  field: undefined,
  setField: (item: DonationAffiliateData) => {
    set({field: item})
  },
  formParam: undefined,
  setForms: (index: number, value: any) => {
    const forms = [...get().forms]
    forms[index] = {
      ...forms[index],
      value: value,
    }
    set({forms: forms})
  },
  generateForms: (formParam: FormProps) => {
    set({formParam: formParam})
    let forms: FormInput[] = []
    forms = [
      {
        id: 'customer_id',
        title: 'Donatur',
        placeholder: 'Donatur...',
        type: 'select-with-text',
        name: 'customer_id',
        value: {
          label:
            get().field?.customer?.values?.find(
              (el) =>
                el.name === 'nama-lengkap' ||
                el.name === 'nama-perusahaan' ||
                el.name === 'nama-komunitas'
            )?.value ?? '-',
          value: get().field?.customer?.id,
        },
        selectData: [],
        disabled: formParam.action === FormAction.VIEW ? true : false,
        required: true,
      },
      {
        id: 'transfer_date',
        title: 'Waktu Tunai',
        placeholder: 'Waktu Transfer...',
        type: 'datetime',
        name: 'transfer_date',
        value: moment(get().field?.transfer_date).toDate(),
        disabled: formParam.action === FormAction.VIEW ? true : false,
        required: true,
      },
      {
        id: 'program_id',
        title: 'Program',
        placeholder: 'Program...',
        type: 'select-with-text',
        name: 'program_id',
        value: {
          label: get().field?.program?.title ?? '-',
          value: get().field?.program?.id,
        },
        selectData: [],
        disabled: formParam.action === FormAction.VIEW ? true : false,
        required: true,
      },
      {
        id: 'payment_id',
        title: 'Payment Method',
        placeholder: 'Payment Method...',
        type: 'select-with-text',
        name: 'payment_id',
        value: {
          label: ` ${get().field?.payment?.description ?? '-'}`,
          value: get().field?.payment?.id,
        },
        disabled: formParam.action === FormAction.VIEW ? true : false,
        required: true,
      },
      // {
      //   id: 'status',
      //   title: 'Status',
      //   placeholder: 'Status...',
      //   type: 'select',
      //   name: 'status',
      //   value: get().field?.status ?? '-',
      //   selectData: [
      //     {label: 'Waiting', value: 'waiting'},
      //     {label: 'Settlement', value: 'settlement'},
      //   ],
      //   disabled: formParam.action === FormAction.VIEW ? true : false,
      //   required: true,
      // },
      {
        id: 'amount',
        title: 'Amount',
        placeholder: 'Amount...',
        type: 'currency',
        name: 'amount',
        value: get().field?.amount ?? 0,
        disabled: formParam.action === FormAction.VIEW ? true : false,
        required: true,
      },
      {
        id: 'channel_id',
        title: 'Channel',
        placeholder: 'Channel...',
        type: 'select-with-text',
        name: 'channel_id',
        value: {
          label: get().field?.channel?.channel_name,
          value: get().field?.channel?.id,
        },
        disabled: formParam.action === FormAction.VIEW ? true : false,
        required: true,
      },
      {
        id: 'depositor',
        title: 'Depositor (Penyetor)',
        placeholder: 'Depositor (Penyetor)...',
        type: 'text',
        name: 'depositor',
        value: get().field?.depositor,
        disabled: formParam.action === FormAction.VIEW ? true : false,
        required: false,
        hidden: true,
      },
      {
        id: 'proof_of_payment',
        title: 'Bukti Transfer',
        placeholder: 'Choose file...',
        type: 'file-upload-v2',
        name: 'proof_of_payment',
        value: get().field?.proof_of_payment ?? '',
        disabled: formParam.action === FormAction.VIEW ? true : false,
        isImage: true,
      },
      {
        id: 'fundraiser_id',
        title: 'Fundraiser',
        placeholder: 'Fundraiser...',
        type: 'select-with-text',
        name: 'fundraiser_id',
        selectData: [],
        value: {
          label: get().field?.fundraiser?.full_name,
          value: get().field?.fundraiser?.id,
        },
        disabled: formParam.action === FormAction.VIEW ? true : false,
        required: true,
      },
      {
        id: 'remarks',
        title: 'Note (Catatan CRM)',
        placeholder: 'Note (Catatan CRM)...',
        type: 'text-area',
        name: 'remarks',
        value: get().field?.remarks ?? '',
        disabled: formParam.action === FormAction.VIEW ? true : false,
        isImage: true,
      },
    ]
    set({forms: forms})
  },
  reset: () => {
    set(formInitialState)
    return
  },
  onSubmit: async (reference: string) => {
    let form = {}
    for (const item of get().forms) {
      form = {
        ...form,
        [item.name]: generateFormLogic(item.type, item.value),
      }
    }
    form = {
      ...form,
      customer_type: reference,
    }

    let res: DonationAffiliateSingleResultInterface = {}
    if (get().formParam.action === FormAction.CREATE) res = await SubmitDonationAffiliate(form)
    if (get().formParam.action === FormAction.UPDATE)
      res = await UpdateDonationAffiliate(get().formParam?.id, form)

    console.log(res)
    if (res.status) {
      toast.success(res.message)
      set({submitDone: true, formLoading: false, field: undefined})
      return
    } else {
      toast.error(res.message)
      set({submitDone: false, formLoading: false})
      return
    }
  },
  setFormSelectData: (index: number, selectData: SelectData[]) => {
    const forms = [...get().forms]
    forms[index] = {
      ...forms[index],
      selectData: selectData,
    }
    set({forms: forms})
  },
}))
