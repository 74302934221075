import {useEffect} from 'react'
import {KTSVG} from '../../../../../../_metronic/helpers'
import FormsComponent from '../../../../forms/components/forms.component'
import {useKorcaFormStore} from '../states/korca-management.state'
import {useProvinceStore} from '../../../auth/provinces-management/states/province.state'
import {useCityStore} from '../../../auth/cities-management/states/city.state'
import {useDistrictStore} from '../../../auth/districts-management/states/district.state'
import {useSubdistrictStore} from '../../../auth/subdistricts-management/states/subdistrict.state'
import {useUserStore} from '../../../auth/user-management/states/user.state'
const KorcaModalComponent = () => {
  const {getUsers, userSelectDatas} = useUserStore()
  const {setFormSelectData} = useKorcaFormStore()

  useEffect(() => {
    document.body.classList.add('modal-open')
    getUsers({
      page: 0,
      size: 100000000,
    })
    return () => {
      document.body.classList.remove('modal-open')
    }
  }, [])

  useEffect(() => {
    if (userSelectDatas?.length > 0) {
      setFormSelectData(0, userSelectDatas)
    }
  }, [userSelectDatas])

  return (
    <>
      <div
        className='modal fade show d-block'
        id='kt_modal_properties'
        role='dialog'
        tabIndex={-1}
        aria-modal='true'
      >
        <div className='modal-dialog modal-dialog-centered mw-1000px'>
          <div className='modal-content'>
            <KorcaModalHeader />

            <div className='modal-body scroll-y mx-5 mx-xl-15 my-7'>
              <KorcaModalFormWrapper />
            </div>
          </div>
        </div>
      </div>
      <div className='modal-backdrop fade show'></div>
    </>
  )
}

const KorcaModalHeader = () => {
  const {setField, formParam} = useKorcaFormStore()

  return (
    <div className='modal-header'>
      <h2 className='fw-bold'>{formParam?.title}</h2>

      <div
        className='btn btn-icon btn-sm btn-active-icon-primary'
        data-kt-users-modal-action='close'
        onClick={() => setField(undefined)}
        style={{cursor: 'pointer'}}
      >
        <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
      </div>
    </div>
  )
}

const KorcaModalFormWrapper = () => {
  const {forms, setForms, setField, onSubmit, setFormSelectData, field} = useKorcaFormStore()
  const {getProvinces, provinceSelectData} = useProvinceStore()
  const {getCities, citySelectData} = useCityStore()
  const {getDistricts, districtSelectData} = useDistrictStore()
  const {getSubdistricts, subdistrictSelectData} = useSubdistrictStore()

  useEffect(() => {
    if (field !== null || field !== undefined) {
      getProvinces({page: 0, size: 100})
      getCities({page: 0, size: 100, province_id: field?.province_id})
      getDistricts({page: 0, size: 100, city_id: field?.city_id})
      getSubdistricts({page: 0, size: 100, district_id: field?.district_id})
    } else {
      getProvinces({page: 0, size: 100})
      getCities({page: 0, size: 100})
      getDistricts({page: 0, size: 100})
      getSubdistricts({page: 0, size: 100})
    }
  }, [field])

  useEffect(() => {
    if (provinceSelectData?.length > 0) {
      setFormSelectData(1, provinceSelectData)
    }
  }, [provinceSelectData])

  useEffect(() => {
    if (citySelectData?.length > 0) {
      setFormSelectData(2, citySelectData)
    }
  }, [citySelectData])

  useEffect(() => {
    if (districtSelectData?.length > 0) {
      setFormSelectData(3, districtSelectData)
    }
  }, [districtSelectData])

  useEffect(() => {
    if (subdistrictSelectData?.length > 0) {
      setFormSelectData(4, subdistrictSelectData)
    }
  }, [subdistrictSelectData])

  return (
    <>
      <form>
        <div
          className='d-flex flex-column scroll-y me-n7 pe-7'
          id='kt_modal_add_user_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_user_header'
          data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
          data-kt-scroll-offset='300px'
        >
          <FormsComponent
            forms={forms}
            setForms={setForms}
            uploadFileCb={(e) => console.log(e)}
            selectWithTextCb={(index, name, value) => {
              console.log(index, name, value)
              if (index === 1) {
                getCities({
                  page: 0,
                  size: 10,
                  province_id: value,
                })
              }
              if (index === 2) {
                getDistricts({
                  page: 0,
                  size: 10,
                  city_id: value,
                })
              }
              if (index === 3) {
                getSubdistricts({
                  page: 0,
                  size: 10,
                  district_id: value,
                })
              }
            }}
            selectWithTextInputChangeCb={(index, name, value) => {
              if (index === 1) {
                getProvinces({
                  page: 0,
                  size: 10,
                  name: value,
                })
              }
              if (index === 2) {
                getCities({
                  page: 0,
                  size: 10,
                  name: value,
                })
              }
              if (index === 3) {
                getDistricts({
                  page: 0,
                  size: 10,
                  name: value,
                })
              }
              if (index === 4) {
                getSubdistricts({
                  page: 0,
                  size: 10,
                  name: value,
                })
              }
            }}
          />
        </div>

        <div className='text-center pt--15'>
          <button
            type='reset'
            onClick={() => setField(undefined)}
            className='btn btn-light me-3'
            data-kt-users-modal-action='cancel'
          >
            Discard
          </button>

          <button
            type='submit'
            className='btn btn-primary'
            data-kt-users-modal-action='submit'
            onClick={(e) => {
              e.preventDefault()
              onSubmit()
            }}
          >
            <span className='indicator-label'>Submit</span>
          </button>
        </div>
      </form>
    </>
  )
}

export default KorcaModalComponent
