import {KTCard, KTSVG} from '../../../../../../_metronic/helpers'
import {LoadingComponent} from '../../../crm/properties-management/components/loading.component'
import {useContext, useEffect} from 'react'
import {useNavigate} from 'react-router-dom'
import ReactPaginate from 'react-paginate'
import {useAuth} from '../../../../auth'
import {FormAction} from '../../../../../interfaces/form-action-interfaces'
import {
  GroupLeaderContext,
  GroupLeaderContextProvider,
  GroupLeaderContextState,
} from '../states/group-leader.context'

import {useGroupStore} from '../states/group.state'
import DonationSegmentChart from '../../../auth/affiliator-management/components/donation-chart.component'
import {generateCurrencyFormat} from '../../../../../helpers/generate-currency-format'
import {BarChart} from '../../../auth/affiliator-management/components/bar-chart.component'
import DashboardCardAffiliatorComponent from '../../../auth/affiliator-management/components/dashboard-card-affiliator.component'
import MiniCardComponent from '../../../auth/affiliator-management/components/mini-card-dashboard.component'
import {
  TotalDonationContextState,
  TotalDonationMemberContext,
  TotalDonationMemberContextProvider,
} from '../../../auth/affiliator-management/states/total-donation.contex'

const MemberListComponent = () => {
  const {
    getMember,
    memberItems,
    getTotalDonationGroupByAffiliate,
    totalDonationGroupByAffiliateItems,
    totalDonationGroupByAffiliateLoading,
    getTotalDonationGroupByTransfer,
    totalDonationGroupByTransferItems,
    totalDonationGroupByTransferLoading,
    getTotalDonationGroupByTunai,
    totalDonationGroupByTunaiItems,
    totalDonationGroupByTunaiLoading,
  } = useGroupStore()
  const {currentUser} = useAuth()
  const navigate = useNavigate()
  const data = memberItems?.group_users?.map((item) => item.user_id)

  useEffect(() => {
    getTotalDonationGroupByAffiliate({
      user_ids: data,
    })
  }, [memberItems])

  useEffect(() => {
    getTotalDonationGroupByTransfer({
      user_ids: data,
    })
  }, [memberItems])

  useEffect(() => {
    getTotalDonationGroupByTunai({
      user_ids: data,
    })
  }, [memberItems])

  useEffect(() => {
    getMember(currentUser?.data?.id)
  }, [])

  const dataSheets = [
    ['Year', 'Transfer', 'Tunai', 'Affiliate'],
    [
      '2023',
      totalDonationGroupByTransferItems,
      totalDonationGroupByTunaiItems,
      totalDonationGroupByAffiliateItems,
    ],
    ['2024', 0, 0, 0],
    ['2025', 0, 0, 0],
  ]
  return (
    <>
      <div className='row gy-5 g-xl-8 mb-5'>
        <DashboardCardAffiliatorComponent
          title='Total Donation Group'
          color='success'
          value={generateCurrencyFormat(
            totalDonationGroupByAffiliateItems +
              totalDonationGroupByTransferItems +
              totalDonationGroupByTunaiItems ?? 0
          )}
          subtitle='Total donasi yang terkumpul'
          loading={false}
        />
        <DashboardCardAffiliatorComponent
          title='Total Member Group'
          color='success'
          value={memberItems?.group_users?.length ?? 0}
          subtitle='Total Member Dalam Group'
          loading={totalDonationGroupByAffiliateLoading}
        />
        <MiniCardComponent
          title='Transfer'
          color='primary'
          value={generateCurrencyFormat(totalDonationGroupByTransferItems ?? 0)}
          icon={
            <i className={`bi bi-send-check-fill text-white fs-2`} style={{marginRight: 10}}></i>
          }
          loading={totalDonationGroupByTransferLoading}
        />
        <MiniCardComponent
          title='Tunai'
          color='warning'
          value={generateCurrencyFormat(totalDonationGroupByTunaiItems ?? 0)}
          icon={<i className={`bi bi-cash-coin text-white fs-2`} style={{marginRight: 10}}></i>}
          loading={totalDonationGroupByTunaiLoading}
        />
        <MiniCardComponent
          title='Affiliate'
          color='danger'
          value={generateCurrencyFormat(totalDonationGroupByAffiliateItems ?? 0)}
          icon={<i className={`bi bi-share text-white fs-2`} style={{marginRight: 10}}></i>}
          loading={false}
        />
        <MiniCardComponent
          title='Target Donasi Ramadhan'
          color='success'
          value={generateCurrencyFormat(10000000)}
          icon={
            <i className={`bi bi-bar-chart-line text-white fs-2`} style={{marginRight: 10}}></i>
          }
          loading={false}
        />
        <MiniCardComponent
          title='Target Donasi Tahunan'
          color='success'
          value={generateCurrencyFormat(300000000)}
          icon={
            <i className={`bi bi-bar-chart-line text-white fs-2`} style={{marginRight: 10}}></i>
          }
          loading={false}
        />
        <div className='d-flex justify-content-between gap-5'>
          <DonationSegmentChart />
          <BarChart data={dataSheets} />
        </div>
      </div>
      <KTCard>
        <div className='card-header border-0 pt-6'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>Groups</span>
            <span className='text-muted mt-1 fw-semibold fs-7'>Group Member</span>
          </h3>
        </div>
        <div className='card-body py-3'>
          <div className='table-responsive'>
            {/* {groupLoading ? <LoadingComponent /> : <></>} */}
            <table className='table table-row-bordered table-striped table-hover able-row-gray-100 align-middle gs-3 gy-3'>
              <thead>
                <tr className='text-start text-muted fw-bold fs-7 text-uppercase gs-0'>
                  <th>Name</th>
                  <th>Status</th>
                  <th>Total Donation</th>
                  <th className='text-end'>Detail</th>
                </tr>
              </thead>
              <tbody className='text-gray-600 fw-semibold'>
                {memberItems?.group_users?.length === 0 || memberItems?.group_users == null ? (
                  <tr>
                    <td colSpan={4}>
                      <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                        No Properties Found
                      </div>
                    </td>
                  </tr>
                ) : (
                  memberItems?.group_users.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td>
                          {
                            <GroupLeaderContextProvider userId={item?.user_id}>
                              <UserDetailComponent />
                            </GroupLeaderContextProvider>
                          }
                        </td>
                        <td>{item.status}</td>
                        <td>
                          {
                            <TotalDonationMemberContextProvider userId={item?.user_id}>
                              <TotalDonationComponent />
                            </TotalDonationMemberContextProvider>
                          }
                        </td>
                        <td className='text-end'>
                          <button
                            className='btn btn-success'
                            onClick={(e) => {
                              e.preventDefault()
                              navigate(`/apps/member-management/member-detail/${item.user_id}`)
                            }}
                          >
                            Detail
                          </button>
                        </td>
                      </tr>
                    )
                  })
                )}
              </tbody>
            </table>
          </div>
        </div>
      </KTCard>
    </>
  )
}

const UserDetailComponent = () => {
  const {user} = useContext<GroupLeaderContextState>(GroupLeaderContext)
  return <>{user?.full_name ? <>{user?.full_name}</> : '-'}</>
}

const TotalDonationComponent = () => {
  const {totalDonation} = useContext<TotalDonationContextState>(TotalDonationMemberContext)
  return (
    <>
      {generateCurrencyFormat(totalDonation) ? <>{generateCurrencyFormat(totalDonation)}</> : '-'}
    </>
  )
}

const GroupMemberListWrapper = () => {
  return (
    <>
      <MemberListComponent />
    </>
  )
}

export {GroupMemberListWrapper}
